<template>
  <div>
    <v-row>
      <v-col md="12" cols="12" class="pb-0 pt-2">
        <DynamicFormControl 
            :control="{
                name: 'Company_' + index,
                type: 'Autocomplete',
                value: newValue.Company,
                config: {
                    label: 'Insurance Company',
                    rules: channelRules(''),
                    col: 12,
                    clearable: true,
                    returnObject: true,
                    itemText:'InsuranceCarrierName',
                    itemValue:'InsuranceCarrierCode',
                    options: insuranceCarrierOptionsFiltered,
                    disabled: disabled,
                    short: true
                }
            }"
            @change="updateValue('Company', $event)" 
        />
      </v-col>      
    </v-row>
    <v-row>
      <v-col md="6" cols="12" class="py-0">
        <DynamicFormControl 
            :control="{
                name: 'ContactName_' + index,
                type: 'TextInput',
                value: newValue.ContactName,
                config: {
                    label: 'Contact Name',
                    rules: channelRules(''),
                    col: 12,
                    disabled: disabled,
                    short: true
                }
            }"
            @change="updateValue('ContactName', $event)" 
        />
      </v-col>
      <v-col md="6" cols="12" class="py-0">
        <DynamicFormControl 
            :control="{
                name: 'ContactPhone_' + index,
                type: 'TextInput',
                value: newValue.ContactPhone,
                config: {
                    label: 'Contact Phone',
                    rules: channelRules('phone'),
                    col: 12,
                    disabled: disabled,
                    short: true,
                    phone: true
                }
            }"
            @change="updateValue('ContactPhone', $event)" 
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12" class="py-0">
        <DynamicFormControl 
            :control="{
                name: 'ContactEmail_' + index,
                type: 'TextInput',
                value: newValue.ContactEmail,
                config: {
                    label: 'Contact Email',
                    rules: 'email',
                    col: 12,
                    disabled: disabled,
                    short: true
                }
            }"
            @change="updateValue('ContactEmail', $event)" 
        />
      </v-col>
      <v-col md="6" cols="12" class="py-0">
        <DynamicFormControl 
            :control="{
                name: 'ContactWebsite_' + index,
                type: 'TextInput',
                value: newValue.ContactWebsite,
                config: {
                    label: 'Contact Website',
                    rules: '',
                    col: 12,
                    disabled: disabled,
                    short: true
                }
            }"
            @change="updateValue('ContactWebsite', $event)" 
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12" class="py-0">
        <DynamicFormControl 
            :control="{
                name: 'ContactedDate_' + index,
                type: 'DateInput',
                value: newValue.ContactedDate,
                config: {
                    label: 'Date of Contact',
                    rules: channelRules('validDate|dateBeforeToday|dateLessThanDaysOld:30'),
                    col: 12,
                    clearable: true,
                    disabled: disabled,
                    short: true
                }
            }"
            @change="updateValue('ContactedDate', $event)" 
        />
      </v-col>
      <v-col md="6" cols="12" class="py-0">
        <DynamicFormControl 
            :control="{
                name: 'DeclineReason_' + index,
                type: 'Dropdown',
                value: newValue.DeclineReason,
                config: {
                    label: 'Declination Reason',
                    rules: channelRules('hasLossesInPast5Years|hasLapseInCoverage'),
                    col: 12,
                    clearable: true,
                    disabled: disabled,
                    short: true,
                    options: [
                      {
                          'value': 'Claim History',
                          'text': 'Claim History'
                      }, 
                      {
                          'value': 'Construction Type',
                          'text': 'Construction Type'
                      },
                      {
                          'value': 'Distance to Water',
                          'text': 'Distance to Water'
                      },
                      {
                          'value': 'Does not meet Underwriting Guidelines',
                          'text': 'Does not meet Underwriting Guidelines'
                      },
                      {
                          'value': 'Lapse in Coverage/No Prior Coverage',
                          'text': 'Lapse in Coverage/No Prior Coverage'
                      },
                      {
                          'value': 'No Market/Capacity Restriction',
                          'text': 'No Market/Capacity Restriction'
                      },
                    ]
                }
            }"
            @change="updateValue('DeclineReason', $event)" 
        />
      </v-col>
    </v-row>
    <br>
  </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'

export default {
  name: 'DiligentEffort',
  components: {
    DynamicFormControl
  },
  data() {
    return {
      newValue: {}
    }
  },
  props: {
    value: {
        type: Object,
        default: () => {}
    },
    errors: {
        type: Array,
        default: () => []
    },
    index: Number,
    disabled: Boolean,
    channel: String,
    otherDEs: {
      type: Array,
      default: () => []
    },
    insuranceCarrierOptions: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'change'
  ],
  created() {
    
  },
  computed: {
    insuranceCarrierOptionsFiltered() {
      const usedCodes = [];
      this.otherDEs.forEach(d => {
        if (d.Company) usedCodes.push(d.Company.InsuranceCarrierCode);
      });
      return this.insuranceCarrierOptions.filter(o => usedCodes.indexOf(o.InsuranceCarrierCode) == -1)
    }
  },
    methods: {
      updateValue(name, $event) {
        this.newValue[name] = $event.value;
        this.$emit("change", this.newValue);
      },
      channelRules(rules) {
        if (this.channel == 'Digital') {
        if (rules) {
          return 'required|' + rules;
        } else {
          return 'required';
        }
      } else {
        return rules;
      }
    }
  },
  watch: {
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
              if (newVal) {
                  this.newValue = newVal;
              } else {
                  this.newValue = {};
              }             
          }
      }
  },
}
</script>
