var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"md":"12","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: 'Company_' + _vm.index,
              type: 'Autocomplete',
              value: _vm.newValue.Company,
              config: {
                  label: 'Insurance Company',
                  rules: _vm.channelRules(''),
                  col: 12,
                  clearable: true,
                  returnObject: true,
                  itemText:'InsuranceCarrierName',
                  itemValue:'InsuranceCarrierCode',
                  options: _vm.insuranceCarrierOptionsFiltered,
                  disabled: _vm.disabled,
                  short: true
              }
          }},on:{"change":function($event){return _vm.updateValue('Company', $event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: 'ContactName_' + _vm.index,
              type: 'TextInput',
              value: _vm.newValue.ContactName,
              config: {
                  label: 'Contact Name',
                  rules: _vm.channelRules(''),
                  col: 12,
                  disabled: _vm.disabled,
                  short: true
              }
          }},on:{"change":function($event){return _vm.updateValue('ContactName', $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: 'ContactPhone_' + _vm.index,
              type: 'TextInput',
              value: _vm.newValue.ContactPhone,
              config: {
                  label: 'Contact Phone',
                  rules: _vm.channelRules('phone'),
                  col: 12,
                  disabled: _vm.disabled,
                  short: true,
                  phone: true
              }
          }},on:{"change":function($event){return _vm.updateValue('ContactPhone', $event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: 'ContactEmail_' + _vm.index,
              type: 'TextInput',
              value: _vm.newValue.ContactEmail,
              config: {
                  label: 'Contact Email',
                  rules: 'email',
                  col: 12,
                  disabled: _vm.disabled,
                  short: true
              }
          }},on:{"change":function($event){return _vm.updateValue('ContactEmail', $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: 'ContactWebsite_' + _vm.index,
              type: 'TextInput',
              value: _vm.newValue.ContactWebsite,
              config: {
                  label: 'Contact Website',
                  rules: '',
                  col: 12,
                  disabled: _vm.disabled,
                  short: true
              }
          }},on:{"change":function($event){return _vm.updateValue('ContactWebsite', $event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: 'ContactedDate_' + _vm.index,
              type: 'DateInput',
              value: _vm.newValue.ContactedDate,
              config: {
                  label: 'Date of Contact',
                  rules: _vm.channelRules('validDate|dateBeforeToday|dateLessThanDaysOld:30'),
                  col: 12,
                  clearable: true,
                  disabled: _vm.disabled,
                  short: true
              }
          }},on:{"change":function($event){return _vm.updateValue('ContactedDate', $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: 'DeclineReason_' + _vm.index,
              type: 'Dropdown',
              value: _vm.newValue.DeclineReason,
              config: {
                  label: 'Declination Reason',
                  rules: _vm.channelRules('hasLossesInPast5Years|hasLapseInCoverage'),
                  col: 12,
                  clearable: true,
                  disabled: _vm.disabled,
                  short: true,
                  options: [
                    {
                        'value': 'Claim History',
                        'text': 'Claim History'
                    }, 
                    {
                        'value': 'Construction Type',
                        'text': 'Construction Type'
                    },
                    {
                        'value': 'Distance to Water',
                        'text': 'Distance to Water'
                    },
                    {
                        'value': 'Does not meet Underwriting Guidelines',
                        'text': 'Does not meet Underwriting Guidelines'
                    },
                    {
                        'value': 'Lapse in Coverage/No Prior Coverage',
                        'text': 'Lapse in Coverage/No Prior Coverage'
                    },
                    {
                        'value': 'No Market/Capacity Restriction',
                        'text': 'No Market/Capacity Restriction'
                    },
                  ]
              }
          }},on:{"change":function($event){return _vm.updateValue('DeclineReason', $event)}}})],1)],1),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }